
import { Vue, Component } from "vue-property-decorator";
import Axios from "axios";
import { AppConfig } from '@/types/config';

@Component
export default class LoginComponent extends Vue {
  public email = null;
  public password = null;
  public error = {
    emailError: [(v:any) => !!v || "Email is mandatory"],
    passwordError: [(v:any) => !!v || "Password is mandatory"]
  };

    // Directly access the SERVER_API_URL from the Vue prototype
    get SERVER_API_URL(): string {
    return (this.$config as AppConfig).SERVER_ADDRESS;
  }

  // Access NODE_ENV similarly
  get NODE_ENV(): string {
    return (this.$config as AppConfig).NODE_ENV;
  }

  async login() {
    try {
      if (!(this.$refs['form'] as any).validate()) return;
      let response = await Axios.post(
        `${this.SERVER_API_URL}/loginCustomer`,
        { username: this.email, password: this.password }
      );

      if (response.status == 203) {
        this.$snotify[response.status == 203 ? 'error':'success'](response.data.message);
      } else if ((response.status = 200)) {
        this.$snotify.success(response.data.message);
        localStorage.setItem("UserInfo", JSON.stringify(response.data.data));
        this.$router.push({ name: "Dashboard" });
      }
    } catch (error) {
      console.log(error, "loginComponent");
    }
  }
}
